import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Date, RichText } from 'prismic-reactjs';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import IconShareFacebook from '../assets/images/vectors/social/share-fb.inline.svg';
import IconShareLinkedin from '../assets/images/vectors/social/share-linkedin.inline.svg';
import IconShareTwitter from '../assets/images/vectors/social/share-tw.inline.svg';

import '../assets/styles/pages/article-page.scss';

const ArticlePage = ({
  data: {
    prismic: { blogData },
  },
  location: { href },
}) => (
  <Layout>
    <SEO title={RichText.asText(blogData.title)} />
    <div className="c-section-mb article-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 col-lg-9 mx-auto">
            <div className="article-page__header">
              <div className="c-caption-2">{RichText.asText(blogData.category.category)}</div>
              <h1 className="c-h2">{RichText.asText(blogData.title)}</h1>
              <div className="d-flex justify-content-between">
                <span>by {blogData.author}</span>
                <span>
                  {Date(blogData.date).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
              </div>
              <div className="article-page__header__image">
                <Img fluid={blogData.imageSharp.childImageSharp.fluid} />
              </div>
            </div>
            <div className="article-page__body">
              <div className="article-page__body__content">{RichText.render(blogData.content)}</div>
              <div className="article-page__body__footer">
                <p>Share This Article</p>
                <div className="article-page__body__footer__share">
                  <FacebookShareButton title="Article" url={href}>
                    <IconShareFacebook className="share-fb" />
                  </FacebookShareButton>
                  <TwitterShareButton title="Article" url={href}>
                    <IconShareTwitter className="share-twitter" />
                  </TwitterShareButton>
                  <LinkedinShareButton title="Article" url={href}>
                    <IconShareLinkedin className="share-linkedin" />
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ArticlePage;

export const pageQuery = graphql`
  query ArticleTemplate($uid: String!, $lang: String!) {
    prismic {
      blogData: blog(lang: $lang, uid: $uid) {
        title
        category {
          ... on PRISMIC_Blog_category {
            category
          }
        }
        date
        author
        content
        image
        imageSharp {
          childImageSharp {
            fluid(maxWidth: 1215, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
